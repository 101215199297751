import classes from './BurnHistoryModalRow.module.css';
import React from 'react';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import { buildEtherscanTxLink } from '../../utils/etherscan';
import { BigNumber as EthersBN } from '@ethersproject/bignumber';
import { Burn } from '../../utils/types';
import clsx from 'clsx';
import auctionActivityClasses from '../AuctionActivity/BidHistory.module.css';
import Davatar from '@davatar/react';
import { useEthers } from '@usedapp/core';
import { useReverseENSLookUp } from '../../utils/ensLookup';
import { containsBlockedText } from '../../utils/moderation/containsBlockedText';
import { i18n } from '@lingui/core';
import { shortENS, useShortAddress, useShortDestination } from '../../utils/addressAndENSDisplayUtils';
import { StandaloneNounCircular } from '../StandaloneNoun';
interface BurnHistoryModalRowProps {
  burn: Burn;
}

const BurnHistoryModalRow: React.FC<BurnHistoryModalRowProps> = props => {
  const { burn } = props;
  const txLink = buildEtherscanTxLink(burn.transactionHash);
  const { library: provider } = useEthers();

  const ens = useReverseENSLookUp(burn.owner);
  const ensMatchesBlocklistRegex = containsBlockedText(ens || '', 'en');
  const shortAddress = useShortAddress(burn.owner);

  const shortDestination = useShortDestination(burn.destination);

  return (
    <li className={clsx(auctionActivityClasses.bidRowCool, classes.bidRow)}>
      <div className={auctionActivityClasses.bidItem}>
        <div className={auctionActivityClasses.leftSectionWrapper}>
          <div className={auctionActivityClasses.bidder}>
            <div className={classes.bidderInfoWrapper}>
              <Davatar size={40} address={burn.owner} provider={provider} />
              <div className={classes.bidderInfoText}>
                <span>
                  {ens && !ensMatchesBlocklistRegex ? shortENS(ens) : shortAddress}
                  <br />
                  <div className={classes.bidDate}>
                    {i18n.date(new Date(EthersBN.from(burn.timestamp).toNumber() * 1000), {
                      dateStyle: 'short',
                      timeStyle: 'short',
                    })}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.nounsterInfoWrapper}>
          <div>
            <StandaloneNounCircular nounId={EthersBN.from(burn.nounsterId)} border={true} />
          </div>
          <div className={classes.nounsterId}>
            {EthersBN.from(burn.nounsterId).toString()}
          </div>
        </div>
        <div className={auctionActivityClasses.rightSectionWrapper}>
          <div className={clsx(classes.bidAmount, auctionActivityClasses.bidAmount)}>
            {shortDestination}
          </div>
          <div className={auctionActivityClasses.linkSymbol}>
            <a href={txLink} target="_blank" rel="noreferrer">
              <div className={classes.linkIcon}>
                <ExternalLinkIcon height={24} width={24} />
              </div>
            </a>
          </div>
        </div>
      </div>
    </li>
  );
};

export default BurnHistoryModalRow;
