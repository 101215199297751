import classes from './RoadmapInfoCard.module.css';
import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';

const RoadmapInfoCard = () => {
  return (
    <>
      <Section fullWidth={false} className={classes.roadmapSection}>
        <Col className={classes.phaseBlockActive}>
          <div>Phase <br></br>One</div>
        </Col>
        <Col className={classes.ellipsesBlock}>
          <div className={classes.ellipses}></div>
          <div className={classes.ellipses}></div>
          <div className={classes.ellipses}></div>
        </Col>
        <Col className={classes.phaseBlockInactive}>
          <div>Phase <br></br>Two</div>
        </Col>
        <Col className={classes.ellipsesBlock}>
          <div className={classes.ellipses}></div>
          <div className={classes.ellipses}></div>
          <div className={classes.ellipses}></div>
        </Col>
        <Col className={classes.phaseBlockInactive}>
          <div>Phase <br></br>Three</div>
        </Col>
        <Col className={classes.ellipsesBlock}>
          <div className={classes.ellipses}></div>
          <div className={classes.ellipses}></div>
          <div className={classes.ellipses}></div>
        </Col>
        <Col className={classes.phaseBlockInactive}>
          <div>Phase <br></br>Four</div>
        </Col>
      </Section >

    </>
  );
};

export default RoadmapInfoCard;
