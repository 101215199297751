import React, { useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import classes from './BurnActivity.module.css';
import burnHistoryClasses from './BurnHistory.module.css';
import Burn from '../Burn';
import BurnHistory from '../BurnHistory';
import BurnActivityTitle from '../BurnActivityTitle';
import BurnHistoryBtn from '../BurnHistoryBtn';
import BurnHistoryModal from '../BurnHistoryModal';
import BurnDescriptionSection from '../BurnDescriptionSection';
import { useAppSelector } from '../../hooks';
import { StandaloneBurningNounster } from '../StandaloneNoun';

interface BurnActivityProps {
}

const BurnActivity: React.FC<BurnActivityProps> = () => {

  const [showBurnHistoryModal, setShowBurnHistoryModal] = useState(false);
  const showBurnModalHandler = () => {
    setShowBurnHistoryModal(true);
  };
  const dismissBurnModalHanlder = () => {
    setShowBurnHistoryModal(false);
  };

  const stateBgColor = useAppSelector(state => state.application.stateBackgroundColor);

  return (
    <>
      {showBurnHistoryModal && (
        <BurnHistoryModal onDismiss={dismissBurnModalHanlder} />
      )}

      <div style={{ backgroundColor: stateBgColor }} >
        <Row className={classes.burnSection}>
          <Col lg={{ span: 7 }} className={classes.auctionActivityCol}>
            <div>
              <BurnActivityTitle content={"Burn your Nounster"} />
              <Burn />
            </div>
          </Col>
          <Col lg={{ span: 5 }} className={classes.nounWrapper}>
            <StandaloneBurningNounster />
          </Col>
        </Row>
      </div>
      <BurnDescriptionSection />
      <div style={{ backgroundColor: stateBgColor }} >
        <Container fluid="xl">
          <BurnActivityTitle content={"Burn History"} />
          <BurnHistory
            max={10}
            classes={burnHistoryClasses}
          />
          <BurnHistoryBtn onClick={showBurnModalHandler} />
        </Container>
      </div>

    </>
  );
};

export default BurnActivity;
