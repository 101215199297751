import React from 'react';
import BurnActivity from '../../components/BurnActivity';
import { setStateBackgroundColor } from '../../state/slices/application';
import { beige } from '../../utils/nounBgColors';
import { useDispatch } from 'react-redux';

interface BurnPageProps { }


const BurnPage: React.FC<BurnPageProps> = props => {

  const dispatch = useDispatch();
  dispatch(setStateBackgroundColor(beige));

  return (
    <>
      <BurnActivity />
    </>
  );
};
export default BurnPage;
