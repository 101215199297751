import { Container } from 'react-bootstrap';
import classes from './Roadmap.module.css';
import { useDispatch } from 'react-redux';
import { setStateBackgroundColor } from '../../state/slices/application';
import { grey } from '../../utils/nounBgColors';
import RoadmapDoc from '../../components/RoadmapDoc';
import RoadmapCurrentSection from '../../components/RoadmapCurrentSection';
import RoadmapInfoCard from '../../components/RoadmapInfoCard';

const RoadmapPage = () => {
  const dispatch = useDispatch();
  dispatch(setStateBackgroundColor(grey));

  const isCoolBackground = true;
  const backgroundColor = isCoolBackground ? 'var(--brand-cool-background)' : 'var(--brand-warm-background)';

  return (

    <>
      <div style={{ backgroundColor: backgroundColor }} className={classes.wrapper}>
        <Container fluid="xl">
          <RoadmapInfoCard />
        </Container>
      </div>
      <RoadmapCurrentSection />
      <RoadmapDoc backgroundColor={backgroundColor} />
    </>
  );
};
export default RoadmapPage;
