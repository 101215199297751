import classes from './NounsIntroSection.module.css';
import Section from '../../layout/Section';
import { Col, Nav } from 'react-bootstrap';
import { } from '@lingui/macro';
import { Link } from 'react-router-dom';

const NounsIntroSection = () => {
  return (
    <>
      <Section fullWidth={false} className={classes.videoSection}>
        <Col lg={6}>
          <div className={classes.textWrapper}>
            <h1>
              The Nounsters are emerging!
            </h1>
            <p>

              Behold, the most terrifying Nouns yet! Nounsters is a community-driven project that makes a
              positive impact by fostering collaboration and expanding the Nounish community to other blockchains,
              other realms, and other planes of existence! From collectors
              and technologists, to non-profits and brands, Nounsters are for everyone; just like Nouns!

            </p>
          </div>
        </Col>
        <Col lg={6} className={classes.embedContainer}>
          <iframe
            title="This is Nouns"
            src="https://player.vimeo.com/video/781320182?h=db24612c0a&color=eaeae5&title=0&byline=0&portrait=0"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <small className={`${classes.videoSubtitle} text-muted`}>
            Check out this video by the original{' '}
            <Nav.Link as={Link} to="nouns.wtf">
              Nouns DAO
            </Nav.Link>{' '}
            to learn more about where we come from.
          </small>
        </Col>
      </Section >

    </>
  );
};

export default NounsIntroSection;
