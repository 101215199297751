import React from 'react';
import ShortAddress from '../ShortAddress';
import dayjs from 'dayjs';
import link from '../../assets/icons/Link.svg';
import { buildEtherscanTxLink } from '../../utils/etherscan';
import { BigNumber as EthersBN } from '@ethersproject/bignumber';
import { usePastBurns } from '../../wrappers/nounToken';
import { Burn } from '../../utils/types';
import { StandaloneNounCircular } from '../StandaloneNoun';
import BurnHistoryHeader from '../BurnHistoryHeader';



const burnItem = (burn: Burn, index: number, classes?: any) => {
  const date = `${dayjs(EthersBN.from(burn.timestamp).toNumber() * 1000).format('MMM DD')} at ${dayjs(
    EthersBN.from(burn.timestamp).toNumber() * 1000,
  ).format('hh:mm a')}`;

  const txLink = buildEtherscanTxLink(burn.transactionHash);
  const isMobile = window.innerWidth < 992;
  let burnDestination = burn.destination;


  return (
    <li key={index} className={classes.bidRowCool}>
      <div className={classes.bidItem}>
        <div className={classes.leftSectionWrapper}>
          <div className={classes.bidder}>
            <div>
              <ShortAddress address={burn.owner} avatar={isMobile ? false : true} />
            </div>
          </div>
          <div className={classes.bidDate}>{date}</div>
        </div>
        <div className={classes.midSectionWrapper}>
          <div>
            <StandaloneNounCircular nounId={EthersBN.from(burn.nounsterId)} border={false} />
          </div>
          <div className={classes.nounsterId}>
            {EthersBN.from(burn.nounsterId).toString()}
          </div>
        </div>
        <div className={classes.rightSectionWrapper}>
          <div className={classes.burnDestination}>
            {burnDestination}
          </div>
        </div>
        <div className={classes.linkSymbol}>
          <a href={txLink} target="_blank" rel="noreferrer">
            <img src={link} width={24} alt="link symbol" />
          </a>
        </div>
      </div>
    </li>
  );
};


const BurnHistory: React.FC<{
  max: number; classes?: any
}> = props => {
  const { max, classes } = props;
  const burns = usePastBurns();
  const sortBurns = [...burns ? burns : []];
  const burnContent =
    sortBurns &&
    sortBurns
      .sort((burn1: Burn, burn2: Burn) => -1 * (EthersBN.from(burn1.timestamp).toNumber() - EthersBN.from(burn2.timestamp).toNumber()))
      .map((burn: Burn, i: number) => {
        return burnItem(burn, i, classes);
      })
      .slice(0, max);

  return <>
    <ul className={classes.bidCollection}>
      <BurnHistoryHeader />
      {burnContent}
    </ul>
  </>;
};

export default BurnHistory;
