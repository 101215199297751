import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';
import { } from '@lingui/macro';

interface DocumentationProps {
  backgroundColor?: string;
}

const Documentation = (props: DocumentationProps = { backgroundColor: '#FFF' }) => {

  return (
    <Section fullWidth={false} className={classes.documentationSection} style={{ background: props.backgroundColor }}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper}>
          <h1>
            WTF?
          </h1>
          <p className={classes.aboutText}>

            Nounsters are an experimental attempt to bridge the gap between Ethereum Non-Fungible Tokens and those that exist on other blockchains, starting with the
            Bitcoin network and their non-fungible Ordinals. Capture a Nounster and swap it for its Ordinal-equivalent on the Bitcoin blockchain!

          </p>
          <p className={classes.aboutText} style={{ paddingBottom: '4rem' }}>

          </p>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Summary
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Nounsters artwork is in the public domain, just like Nouns.
                </li>
                <li>
                  One Nounster is trustlessly auctioned every hour for the next 100 hours.
                </li>
                <li>
                  Settlement of one auction kicks off the next.
                </li >
                <li>
                  All Nounsters already have a Bitcoin-Ordinal counterpart.
                </li >
                <li>
                  Nounsters will continue to exist on the Ethereum network until their owner chooses to burn them for their permanent Ordinal counterpart.
                </li>
                <li>
                  There are thirteen Nounster types, which help to determine their traits.
                </li >
                <li>
                  Future projects in the roadmap will continue to develop the utility of Nounsters; and any of their off-chain counterparts.
                </li >
              </ul >
            </Accordion.Body >
          </Accordion.Item >

          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Hourly Auctions
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>

                The Nounsters Auction Contract will act as a distribution mechanism for Nounsters,
                auctioning one Nounster every hour until all 111 are claimed.

              </p>

              <p className={classes.aboutText}>

                Each time an auction is settled, the settlement transaction will also cause a new
                Nounster to be minted and a new 1 hour auction to begin.{' '}

              </p >
            </Accordion.Body >
          </Accordion.Item >
          <Accordion.Item eventKey="2" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Nounsters!
            </Accordion.Header>
            <Accordion.Body>

              <ul>
                <li>
                  Each Nounster Type governs the generation of traits, statistics, and special abilities of the Nounster.
                </li>
                <li>
                  Nounsters are assigned statistics at birth that aren't yet visible to the public.
                </li>
                <li>
                  You can buy, sell, and trade Nounsters like any other Ethereum NFT.
                </li>
                <li>
                  There are thirteen Nounster types:
                  <ul>
                    <li>Aberration</li>
                    <li>Beast</li>
                    <li>Celestial</li>
                    <li>Construct</li>
                    <li>Dragon</li>
                    <li>Elemental</li>
                    <li>Fey</li>
                    <li>Fiend</li>
                    <li>Giant</li>
                    <li>Monstrosity</li>
                    <li>Ooze</li>
                    <li>Plant</li>
                    <li>Undead</li>
                  </ul>
                </li>
              </ul >

            </Accordion.Body >
          </Accordion.Item >
        </Accordion >
      </Col >
    </Section >
  );
};
export default Documentation;
