import classes from './BurnHistoryModal.module.css';
import ReactDOM from 'react-dom';
import React from 'react';
import { XIcon } from '@heroicons/react/solid';
import { Burn } from '../../utils/types';
import BurnHistoryModalRow from '../BurnHistoryModalRow';
import { } from '@lingui/macro';
import { usePastBurns } from '../../wrappers/nounToken';

export const Backdrop: React.FC<{ onDismiss: () => void }> = props => {
  return <div className={classes.backdrop} onClick={props.onDismiss} />;
};

const BurnHistoryModalOverlay: React.FC<{
  onDismiss: () => void;
}> = props => {
  const { onDismiss } = props;

  const burns = usePastBurns();

  return (
    <>
      <div className={classes.closeBtnWrapper}>
        <button onClick={onDismiss} className={classes.closeBtn}>
          <XIcon className={classes.icon} />
        </button>
      </div>

      <div className={classes.modal}>
        <div className={classes.content}>
          <div className={classes.header}>
            <div className={classes.title}>
              <h2>
                All Burns
              </h2>
            </div>
          </div>
          <div className={classes.bidWrapper}>
            {burns && burns.length > 0 ? (
              <ul>
                {burns?.map((burn: Burn, i: number) => {
                  return <BurnHistoryModalRow burn={burn} />;
                })}
              </ul>
            ) : (
              <div className={classes.nullStateText}>
                Burns will appear here
              </div>
            )}
          </div>
        </div>
      </div >
    </>
  );
};

const BurnHistoryModal: React.FC<{
  onDismiss: () => void;
}> = props => {
  const { onDismiss } = props;
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onDismiss={onDismiss} />,
        document.getElementById('backdrop-root')!,
      )}
      {ReactDOM.createPortal(
        <BurnHistoryModalOverlay onDismiss={onDismiss} />,
        document.getElementById('overlay-root')!,
      )}
    </>
  );
};

export default BurnHistoryModal;
