import { BigNumber as EthersBN } from 'ethers';
import { INounSeed } from '../../wrappers/nounToken';
import Noun from '../Noun';
import { Link } from 'react-router-dom';
import classes from './StandaloneNoun.module.css';
import { useDispatch } from 'react-redux';
import { setOnDisplayAuctionNounId } from '../../state/slices/onDisplayAuction';
import nounClasses from '../Noun/Noun.module.css';
import Image from 'react-bootstrap/Image';
import emerging from '../../assets/nounstersCaveEmerging.png';
import pending from '../../assets/nounstersCavePending.png';
import blank from '../../assets/blank.png';
import burnedNounster from '../../assets/burnedNounster.png';

interface StandaloneNounProps {
  nounId: EthersBN;
}
interface StandaloneCircularNounProps {
  nounId: EthersBN;
  border?: boolean;
}

interface StandaloneNounWithSeedProps {
  nounId: EthersBN;
  onLoadSeed?: (seed: INounSeed) => void;
  shouldLinkToProfile: boolean;
}

export const getNoun = (nounId: string | EthersBN, seed: INounSeed | undefined) => {
  const id = nounId.toString();
  const name = `Nounster ${id}`;
  const description = `Nounster ${id} is a member of the Nounsters Ordinals Collection`;
  //const { parts, background } = getNounData(seed);
  //const image = `data:image/svg+xml;base64,${btoa(buildSVG(parts, data.palette, background))}`;
  const image = `https://nounsters.infura-ipfs.io/ipfs/QmfK8GryeJVNyoX1cQh2KRsDZAWpHusqTnAXECGTweu1ih/images/${id}.png`;

  return {
    name,
    description,
    image,
  };
};

export const StandaloneNounImage: React.FC<StandaloneNounProps> = (props: StandaloneNounProps) => {
  const { nounId } = props;
  const seed = undefined;
  const noun = getNoun(nounId, seed);

  return <Image src={noun ? noun.image : ''} fluid />;
};

const StandaloneNoun: React.FC<StandaloneNounProps> = (props: StandaloneNounProps) => {
  const { nounId } = props;
  const seed = undefined;
  const noun = getNoun(nounId, seed);

  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionNounId(nounId.toNumber()));
  };

  return (
    <Link
      to={'/nounster/' + nounId.toString()}
      className={classes.clickableNoun}
      onClick={onClickHandler}
    >
      <Noun imgPath={noun ? noun.image : ''} alt={noun ? noun.description : 'Nounster'} />
    </Link>
  );
};

export const StandaloneNounCircular: React.FC<StandaloneCircularNounProps> = (
  props: StandaloneCircularNounProps,
) => {
  const { nounId, border } = props;
  const seed = undefined;
  const noun = getNoun(nounId, seed);

  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionNounId(nounId.toNumber()));
  };

  if (!nounId) return <Noun imgPath="" alt="Nounster" />;

  return (
    <Link
      to={'/nounster/' + nounId.toString()}
      className={classes.clickableNoun}
      onClick={onClickHandler}
    >
      <Noun
        imgPath={noun ? noun.image : ''}
        alt={noun ? noun.description : 'Nounster'}
        wrapperClassName={nounClasses.circularNounWrapper}
        className={border ? nounClasses.circleWithBorder : nounClasses.circular}
      />
    </Link>
  );
};

export const StandaloneNounRoundedCorners: React.FC<StandaloneNounProps> = (
  props: StandaloneNounProps,
) => {
  const { nounId } = props;
  const seed = undefined;
  const noun = getNoun(nounId, seed);

  const dispatch = useDispatch();
  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionNounId(nounId.toNumber()));
  };

  return (
    <Link
      to={'/nounster/' + nounId.toString()}
      className={classes.clickableNoun}
      onClick={onClickHandler}
    >
      <Noun
        imgPath={noun ? noun.image : ''}
        alt={noun ? noun.description : 'Nounster'}
        className={nounClasses.rounded}
      />
    </Link>
  );
};

export const StandaloneNounWithSeed: React.FC<StandaloneNounWithSeedProps> = (
  props: StandaloneNounWithSeedProps,
) => {
  const { nounId, onLoadSeed, shouldLinkToProfile } = props;

  const dispatch = useDispatch();
  const seed = undefined;

  if (!nounId || !onLoadSeed) return <Noun imgPath="" alt="Nounster" />;

  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionNounId(nounId.toNumber()));
  };

  const { image, description } = getNoun(nounId, seed);

  const noun = <>
    <Noun imgPath={image} alt={description} />
  </>;
  const nounWithLink = (
    <Link
      to={'/nounster/' + nounId.toString()}
      className={classes.clickableNoun}
      onClick={onClickHandler}
    >
      {noun}
    </Link>
  );
  return shouldLinkToProfile ? nounWithLink : noun;
};


export const StandaloneNounWithCave: React.FC<StandaloneNounWithSeedProps> = (
  props: StandaloneNounWithSeedProps,
) => {
  const { nounId, onLoadSeed, shouldLinkToProfile } = props;

  const dispatch = useDispatch();
  const seed = undefined;

  if (!nounId || !onLoadSeed) return <Noun imgPath="" alt="Nounster" />;

  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionNounId(nounId.toNumber()));
  };

  const { image, description } = getNoun(nounId, seed);

  const noun = <>
    <Noun imgPath={image} alt={description} overlayImgPath={emerging} />
  </>;
  const nounWithLink = (
    <Link
      to={'/nounster/' + nounId.toString()}
      className={classes.clickableNoun}
      onClick={onClickHandler}
    >
      {noun}
    </Link>
  );
  return shouldLinkToProfile ? nounWithLink : noun;
};


export const StandaloneBlankWithCave = (
) => {
  const noun = <>
    <Noun imgPath={blank} alt={"Pending"} overlayImgPath={pending} />
  </>;
  return noun;
};

export const StandaloneBurningNounster = (
) => {
  const noun = <>
    <Noun imgPath={burnedNounster} alt={"This is fine."} className={nounClasses.burningNounster} />
  </>;
  return noun;
};

export default StandaloneNoun;
