import classes from './BurnDescriptionSection.module.css';
import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import { } from '@lingui/macro';

const BurnDescriptionSection = () => {
  return (
    <>
      <Section fullWidth={false} className={classes.videoSection}>
        <Col lg={6}>
          <div className={classes.textWrapper}>
            <h1>
              WTF? Burn?!
            </h1>
            <p>

              In order to facilitate the conversion of your Ethereum Nounster to a Bitcoin Ordinal Nounster, you
              need to tell us the Satoshi-safe Bitcoin wallet address that you would like it sent to while
              confirming that you're the wallet of the Ethereum version.
              You'll need to include this information in the burn transaction, which happens when you
              use the fields above.

            </p>
          </div>
        </Col>
        <Col lg={6} className={classes.textWrapper}>

          <h1>
            Satoshi-Safe?
          </h1>
          <p>

            You can't receive Ordinal NFTs to a traditional Bitcoin wallet, as they don't track individual Satoshis.
            Instead, you'll need to use a wallet like Ordinals Wallet or Sparrow Wallet, as they allow you to lock individual
            Satoshis. Failure to confirm your wallet is Satoshi-safe, or entering invalid wallet information, will result
            in the burning of your Nounster with no way to receive its Ordinal counterpart. Be very careful! Don't be afraid to
            reach out on Twitter if you have questions!

          </p >
        </Col >
      </Section >

    </>
  );
};

export default BurnDescriptionSection;
