import classes from './BurnActivityTitle.module.css';

const BurnActivityTitle: React.FC<{ content: string }> = props => {
  const { content } = props;
  return (
    <div className={classes.wrapper}>
      <h1>
        {content}
      </h1>
    </div>
  );
};
export default BurnActivityTitle;
