import React from 'react';
import classes from "./BurnHistoryHeader.module.css";

const BurnHistoryHeader: React.FC<{
}> = props => {

  return <li key={0} className={classes.bidRowCool}>
    <div className={classes.bidItem}>
      <div className={classes.leftSectionWrapper}>
        <div className={classes.nounsterId}>
          <div>
            Burn Address
          </div>
        </div>
        <div>
        </div>
      </div>
      <div className={classes.midSectionWrapper}>
        <div className={classes.nounsterId}>
          Nounster
        </div>
      </div>
      <div className={classes.rightSectionWrapper}>
        <div className={classes.nounsterId}>
          Bitcoin Wallet Destination
        </div>
      </div>
      <div>

      </div>
    </div>
  </li>
};

export default BurnHistoryHeader;
