import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './RoadmapDoc.module.css';
import Accordion from 'react-bootstrap/Accordion';
import { } from '@lingui/macro';

interface DocumentationProps {
  backgroundColor?: string;
}

const RoadmapDoc = (props: DocumentationProps = { backgroundColor: '#FFF' }) => {

  return (
    <Section fullWidth={false} className={classes.documentationSection} style={{ background: props.backgroundColor }}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper}>
          <h1>
            ROADMAP
          </h1>
          <p className={classes.aboutText}>

            The Nounsters project has been sliced into four phases in order to focus our team's priorities on achievable goals
            and development of the Nounsters brand block-by-block. Below are the current outlines for our four phases. Stay tuned
            for updates to the roadmap as we continue to develop!

          </p>
          <p className={classes.aboutText} style={{ paddingBottom: '4rem' }}>

          </p>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Phase One - Ordinal Nounsters
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Ordinal Nounsters.
                </li>
                <li>
                  One hundred and eleven Ordinal Nounsters will emerge to establish the Nounsters brand.
                </li>
              </ul >
            </Accordion.Body >
          </Accordion.Item >

          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Phase Two - Nounsters DAO
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>

                Phase Two will introduce the Nounsters DAO, an organization modeled after the original
                Nouns DAO (which uses Compound Governance) and will serve as a way for the community
                to guide the direction of Nounsters.

              </p>
              <p className={classes.aboutText}>
                Upon introduction of the Nounsters DAO, the Nounsters will begin emerging at routine intervals
                and will serve as the governance tokens for the DAO.
              </p>

            </Accordion.Body >
          </Accordion.Item >
          <Accordion.Item eventKey="2" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Phase Three - Nounsters Frenzy
            </Accordion.Header>
            <Accordion.Body>

              <ul>
                <li>
                  Phase Three will introduce a number of ways to utilize Nounsters, including (but not limited to)
                  gamification and development of Nounster abilities, traits, and powers.
                </li>
                <li>
                  Nounsters are assigned statistics at birth that aren't yet visible to the public, which will be revealed
                  during Phase Three and will be usable by Nounsters holders.
                </li>
              </ul >

            </Accordion.Body >
          </Accordion.Item >
          <Accordion.Item eventKey="3" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Phase Four - Nounsters... and Beyond!
            </Accordion.Header>
            <Accordion.Body>

              <ul>
                <li>
                  Phase Four introduces the Nounster Hunter's Board, a service that allows the Nounsters DAO to fund
                  projects that further proliferate the Nounsters brand, focusing on projects that themselves promote
                  gamification and entertainment.
                </li>
              </ul >

            </Accordion.Body >
          </Accordion.Item >
        </Accordion >
      </Col >
    </Section >
  );
};
export default RoadmapDoc;
