import { NounsTokenContractFunction } from '../../wrappers/nounToken';
import { useEthers, useContractFunction } from '@usedapp/core';
import { connectContractToSigner } from '@usedapp/core/dist/cjs/src/hooks';
import { useAppSelector } from '../../hooks';
import { utils, BigNumber as EthersBN } from 'ethers';
import React, { useEffect, useState, useRef, ChangeEvent, useCallback } from 'react';
import classes from './Burn.module.css';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { useAppDispatch } from '../../hooks';
import { AlertModal, setAlertModal } from '../../state/slices/application';
import { NounstersOrdinalTokenFactory } from '@nouns/sdk';
import config from '../../config';
import WalletConnectModal from '../WalletConnectModal';
import { useActiveLocale } from '../../hooks/useActivateLocale';
import responsiveUiUtilsClasses from '../../utils/ResponsiveUIUtils.module.css';


const Burn: React.FC<{
}> = props => {
  const activeAccount = useAppSelector(state => state.account.activeAccount);
  const { library } = useEthers();
  const activeLocale = useActiveLocale();

  const nounstersOrdinalTokenContract = new NounstersOrdinalTokenFactory().attach(
    config.addresses.nounstersOrdinalToken,
  );

  const account = useAppSelector(state => state.account.activeAccount);

  const nounsterInputRef = useRef<HTMLInputElement>(null);
  const destinationInputRef = useRef<HTMLInputElement>(null);

  const [nounsterInput, setNounsterInput] = useState('');
  const [destinationInput, setDestinationInput] = useState('');

  const [burnButtonContent, setBurnButtonContent] = useState({
    loading: false,
    content: account ? <>Burn</> : <>Login!</>,
  });

  const [showConnectModal, setShowConnectModal] = useState(false);

  const hideModalHandler = () => {
    setShowConnectModal(false);
  };

  const dispatch = useAppDispatch();
  const setModal = useCallback((modal: AlertModal) => dispatch(setAlertModal(modal)), [dispatch]);

  const { send: burnNounster, state: burnTransactionState } = useContractFunction(
    nounstersOrdinalTokenContract,
    NounsTokenContractFunction.burn,
  );

  const nounsterInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

    // disable decimal point digits
    if (input.includes('.') && event.target.value.split('.')[1].length > 0) {
      return;
    }

    setNounsterInput(event.target.value);
  };
  const destinationInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setDestinationInput(event.target.value);
  };

  const burnNounsterHandler = async () => {

    const contract = connectContractToSigner(nounstersOrdinalTokenContract, undefined, library);
    //const destinationData = utils.solidityPack(['bytes'], [utils.toUtf8Bytes(destinationInput)]);
    const destinationData = utils.hexlify(utils.toUtf8Bytes(destinationInput));
    console.log("Destination Data:", destinationData)
    try {
      const gasLimit = await contract.estimateGas.burn(nounsterInput, destinationData);
      burnNounster(EthersBN.from(nounsterInput), destinationData, { gasLimit: gasLimit.add(10_000) });
      clearNounsterInput();
    } catch (e: any) {
      console.log("Error!", e)
      setModal({
        show: true,
        title: <>Error!</>,
        message: (e.data ? e.data.message : e.message),
      });
      return;
    }

  }

  const clearNounsterInput = () => {
    if (nounsterInputRef.current) {
      nounsterInputRef.current.value = ' ';
    }
    if (destinationInputRef.current) {
      destinationInputRef.current.value = ' ';
    }
  };


  // successful bid using redux store state
  useEffect(() => {
    if (!account) return;

    // tx state is mining
    const isMiningUserTx = burnTransactionState.status === 'Mining';
    if (isMiningUserTx) {
      burnTransactionState.status = 'Success';
      setModal({
        title: <>Success</>,
        message: <>Nounster burned successfully!</>,
        show: true,
      });
      setBurnButtonContent({ loading: false, content: <>Burn!</> });
      clearNounsterInput();
    }
  }, [burnTransactionState, account, setModal]);


  // placing bid transaction state hook
  useEffect(() => {
    switch (burnTransactionState.status) {
      case 'None':
        setBurnButtonContent({
          loading: false,
          content: <>Burn!</>,
        });
        break;
      case 'Mining':
        setBurnButtonContent({ loading: true, content: <></> });
        break;
      case 'Fail':
        setModal({
          title: <>Transaction Failed</>,
          message: burnTransactionState?.errorMessage || <>Please try again.</>,
          show: true,
        });
        setBurnButtonContent({ loading: false, content: <>Burn!</> });
        break;
      case 'Exception':
        setModal({
          title: Error!,
          message: burnTransactionState?.errorMessage || <>Please try again.</>,
          show: true,
        });
        setBurnButtonContent({ loading: false, content: <>Burn!</> });
        break;
    }
  }, [burnTransactionState, setModal]);

  const isDisabled = burnTransactionState.status === 'Mining' || !activeAccount;

  return (
    <>
      {showConnectModal && activeAccount === undefined && (
        <WalletConnectModal onDismiss={hideModalHandler} />
      )}
      <div className={classes.burnWrapper}>
        <div></div>
        <div>Enter your Bitcoin wallet address</div>
        <InputGroup>
          {(
            <>
              <span className={classes.customPlaceholderText}>
                {!destinationInput ? (
                  <>
                    <span
                      className={
                        activeLocale === 'ja-JP' ? responsiveUiUtilsClasses.disableSmallScreens : ''
                      }
                    >
                      BTC Address
                    </span>
                  </>
                ) : (
                  ''
                )}
              </span>
              <FormControl
                className={classes.nounsterInput}
                type="string"
                min=""
                onChange={destinationInputHandler}
                ref={destinationInputRef}
                value={destinationInput}
              />
            </>
          )}
        </InputGroup>
        <div>
        </div>
        <div></div>
        <div>Enter the Nounster ID to burn</div>
        <InputGroup>
          {(
            <>
              <span className={classes.customPlaceholderText}>
                {!nounsterInput ? (
                  <>
                    <span
                      className={
                        activeLocale === 'ja-JP' ? responsiveUiUtilsClasses.disableSmallScreens : ''
                      }
                    >
                      Nounster ID
                    </span>
                  </>
                ) : (
                  ''
                )}
              </span>
              <FormControl
                className={classes.nounsterInput}
                type="number"
                min="0"
                onChange={nounsterInputHandler}
                ref={nounsterInputRef}
                value={nounsterInput}
              />
            </>
          )}
        </InputGroup>
        <div></div>
        <div>Burn that Nounster!</div>
        <div></div>
        {
          (
            <Button
              className={classes.bidBtn}
              onClick={burnNounsterHandler}
              disabled={isDisabled}
            >
              {burnButtonContent.content}
            </Button>
          )
        }
      </div >
    </>
  );
};
export default Burn;
