import config from '../config';

export const resolveNounContractAddress = (address: string) => {
  switch (address.toLowerCase()) {
    case config.addresses.nounstersAuctionHouse.toLowerCase():
      return 'Nounsters Auction House';
    default:
      return undefined;
  }
};
