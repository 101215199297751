import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber } from '@ethersproject/bignumber';

export interface BurnState {
  nounsterId: BigNumber;
  owner: string;
  destination: string;
  transactionHash: string;
  timestamp: BigNumber;
}

interface PastBurnsState {
  pastBurns: BurnState[];
}

const initialState: PastBurnsState = {
  pastBurns: [],
};

const reduxSafePastBurns = (data: any): BurnState[] => {
  const burns = data.data.burns as any[];
  if (burns.length < 0) return [];
  const pastBurns: BurnState[] = burns.map(burn => {
    return {
      nounsterId: BigNumber.from(burn.nounster.id).toJSON(),
      owner: burn.owner.id,
      destination: burn.destination,
      transactionHash: burn.id,
      timestamp: BigNumber.from(burn.blockTimestamp).toJSON(),
    };
  });
  return pastBurns;
};

const pastBurnsSlice = createSlice({
  name: 'pastBurns',
  initialState: initialState,
  reducers: {
    addBurns: (state, action: PayloadAction<any>) => {
      state.pastBurns = reduxSafePastBurns(action.payload);
    },
  },
});

export const { addBurns } = pastBurnsSlice.actions;

export default pastBurnsSlice.reducer;
