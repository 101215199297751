import classes from './RoadmapCurrentSection.module.css';
import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';

const RoadmapCurrentSection = () => {
  return (
    <>
      <Section fullWidth={false} className={classes.videoSection}>
        <Col lg={6}>
          <div className={classes.textWrapper}>
            <h1>
              The Nounsters are emerging!
            </h1>
            <p>

              Phase One outlines the emergence of the Nounsters. One hundred and eleven Ordinal Nounsters will
              carve out a path through the bitverse for the other Nounsters to begin pouring out behind them.

            </p>
          </div>
        </Col>
        <Col lg={6}>
          <div className={classes.textWrapper}>
            Phase One is the only time that Ordinal Nounsters will be available, and is the only time that Nounsters will
            adventure on the Bitcoin chain. However, Phase One is not the only time that Ordinal Nounsters will be utilized
            by the Nounsters team. Claiming your Ordinal Nounster will:
          </div>
          <ul>
            <li>
              Secure access to future Nounster whitelists,
            </li>
            <li>
              Allow access to an Ordinals-only Discord channel to participate in Nounsters development,
            </li>
            <li>
              Exclusive access to airdrops, giveaways, and more!
            </li>
          </ul>

        </Col>
      </Section >

    </>
  );
};

export default RoadmapCurrentSection;
