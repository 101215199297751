import burnBtnClasses from './BurnHistoryBtn.module.css';

const BurnHistoryBtn: React.FC<{ onClick: () => void }> = props => {
  const { onClick } = props;

  const isCool = true;

  return (
    <div
      className={isCool ? burnBtnClasses.bidHistoryWrapperCool : burnBtnClasses.bidHistoryWrapperWarm}
      onClick={onClick}
    >
      <div className={burnBtnClasses.bidHistory}>
        View all burns
      </div>
    </div >
  );
};
export default BurnHistoryBtn;
