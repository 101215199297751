import { Container, Row, Col } from 'react-bootstrap';
import classes from './Coming.module.css';
import { StandaloneBlankWithCave } from '../../components/StandaloneNoun';
import { externalURL, ExternalURL } from '../../utils/externalURL';
import Link from '../../components/Link';
import NounsIntroSection from '../../components/NounsIntroSection';
import Documentation from '../../components/Documentation';
import { useDispatch } from 'react-redux';
import { setStateBackgroundColor } from '../../state/slices/application';
import { grey } from '../../utils/nounBgColors';

const ComingPage = () => {
  const twitterURL = externalURL(ExternalURL.twitter);
  const dispatch = useDispatch();
  dispatch(setStateBackgroundColor(grey));

  const isCoolBackground = true;
  const backgroundColor = isCoolBackground ? 'var(--brand-cool-background)' : 'var(--brand-warm-background)';

  return (

    <>
      <div style={{ backgroundColor: backgroundColor }} className={classes.wrapper}>
        <Container fluid="xl">
          <Row>
            <Col lg={{ span: 6 }} className={classes.nounContentCol}>
              <div className={classes.nounWrapper}>
                <StandaloneBlankWithCave />
              </div>
            </Col>
            <Col lg={{ span: 6 }} className={classes.textWrapper}>
              <h1 className={classes.heading}>
                They're almost here...
              </h1>
              <h2 className={classes.bodyText}>
                Follow us on{' '}
                <Link text={<>Twitter</>} url={twitterURL} leavesPage={true} />
                {' '}for the latest updates!
              </h2>
            </Col>
          </Row>
          <br></br>
        </Container>
      </div>
      <NounsIntroSection />
      <Documentation
        backgroundColor={backgroundColor}
      />
    </>
  );
};
export default ComingPage;
